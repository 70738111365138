export const initialState = {
  applets: [],
  services: [],
  stories: [],
  getMoreSections: [],
  diyPath: "",
  belowFoldPath: "",
  belowFoldLoaded: false,
  loading: false,
}

const setGetMoreSections = section => {
  return {
    type: "SET_GET_MORE_SECTIONS",
    section,
  }
}

const setIsLoading = isLoading => {
  return {
    type: "TOGGLE_IS_LOADING",
    isLoading,
  }
}

const updatePagination = offset => {
  return {
    type: "UPDATE_PAGINATION",
    offset,
  }
}

const setBelowFoldLoaded = () => ({ type: "SET_BELOW_FOLD_LOADED" })

const setBelowFoldContent = (applets, stories, services) => {
  return {
    type: "SET_BELOW_FOLD_CONTENT",
    applets,
    stories,
    services,
  }
}

export const fetchSections = () => (dispatch, getState) => {
  const offset = getState().getMoreResults.offset
  const limit = getState().getMoreResults.limit

  if (offset >= limit) {
    return
  }

  dispatch(setIsLoading(true))

  const results = getState().getMoreResults

  //These offsets represent how we are constructing a single Get More section on the page. Right now we use two stories, four applets, and two services for each section.

  const appletsOffset = offset * 8
  const servicesOffset = offset * 2
  const storiesOffset = offset * 2

  const getMoreSection = {
    applets: results.applets.slice(appletsOffset, appletsOffset + 8),
    services: results.services.slice(servicesOffset, servicesOffset + 2),
    stories: results.stories.slice(storiesOffset, storiesOffset + 2),
    appletsOffset,
    servicesOffset,
    storiesOffset,
  }

  dispatch(updatePagination(offset + 1))
  dispatch(setGetMoreSections(getMoreSection))
  dispatch(setIsLoading(false))
}

export const fetchBelowFoldDiscoverContent = () => (dispatch, getState) => {
  dispatch(setIsLoading(true))
  const { belowFoldPath, belowFoldLoaded } = getState().getMoreResults

  return fetch(`${belowFoldPath}?pagination=rest_expanded`, {
    headers: {
      Accept: "application/json",
    },
  })
    .then(res => res.json())
    .then(([applets, stories, services]) => {
      dispatch(setBelowFoldContent(applets, stories, services))
      if (!belowFoldLoaded) {
        dispatch(fetchSections())
      }
      dispatch(setBelowFoldLoaded())
    })
    .finally(() => dispatch(setIsLoading(false)))
}

export default function getMoreResults(state = initialState, action) {
  switch (action.type) {
    case "SET_APPLETS":
      return {
        ...state,
        applets: [...state.applets, ...action.applets],
      }

    case "SET_SERVICES":
      return {
        ...state,
        services: [...state.services, ...action.services],
      }

    case "SET_STORIES":
      return {
        ...state,
        stories: [...state.stories, ...action.stories],
      }

    case "SET_GET_MORE_SECTIONS":
      return {
        ...state,
        getMoreSections: state.getMoreSections ? [...state.getMoreSections, action.section] : [action.section],
      }

    case "TOGGLE_IS_LOADING":
      return {
        ...state,
        loading: action.isLoading,
      }

    case "SET_BELOW_FOLD_CONTENT":
      return {
        ...state,
        applets: [...state.applets, ...action.applets],
        stories: [...state.stories, ...action.stories],
        services: [...state.services, ...action.services],
      }

    case "SET_BELOW_FOLD_LOADED":
      return {
        ...state,
        belowFoldLoaded: true,
      }

    case "UPDATE_PAGINATION":
      return {
        ...state,
        offset: action.offset,
      }

    default: {
      return state
    }
  }
}
