export const initialState = {
  currentView: "discover_all",
  origin: "",
}

export const toggleView = view => {
  return {
    type: "SET_VIEW",
    view,
  }
}

export const setOrigin = origin => {
  return {
    type: "SET_ORIGIN",
    origin,
  }
}

export default function viewToggle(state = initialState, action) {
  switch (action.type) {
    case "SET_VIEW":
      return {
        ...state,
        currentView: action.view,
      }

    case "SET_ORIGIN":
      return {
        ...state,
        origin: action.origin,
      }

    default: {
      return state
    }
  }
}
