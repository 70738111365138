import { gql as gqlFunc } from "./utils"
import { fetchAuthenticityToken } from "app/scripts/refresh_form_authenticity_token"

export const gql = gqlFunc

const UpdateEmbeddedRedirectUrisMutation = gql`
  mutation UpdateEmbeddedRedirectUrisMutation($serviceModuleName: String!, $redirects: [String!]) {
    updateEmbeddedRedirectUris(input: { channel_module_name: $serviceModuleName, embedded_redirect_uris: $redirects }) {
      channel {
        embedded_redirect_uris
      }
      errors {
        attribute
        message
      }
    }
  }
`

const SendDownloadLinkMutation = gql`
  mutation SendDownloadLinkMutation($phoneNumber: String!, $platform: String!, $forApplet: Boolean) {
    sendDownloadLinkMutation(input: { phone_number: $phoneNumber, platform: $platform, for_applet: $forApplet }) {
      errors {
        attribute
        message
      }
      status
    }
  }
`

const AddChannelToFavoriteChannelsMutation = gql`
  mutation AddChannelToFavoriteChannelsMutation($channelNames: [String]!) {
    addChannelToFavoriteChannelsMutation(input: { channel_names: $channelNames }) {
      favorite_channels {
        id
      }
      errors {
        attribute
        message
      }
    }
  }
`

const InviteUserToOrganizationMutation = gql`
  mutation InviteUserToOrganizationMutation($emailOrLogin: String!, $orgId: String!, $allowAccountCreation: Boolean) {
    inviteUserToOrganization(
      input: { user_login: $emailOrLogin, organization_id: $orgId, create_magick_account: $allowAccountCreation }
    ) {
      errors {
        attribute
        message
      }
      org {
        pending_invitations {
          id
          login
          email
          email_for_org_users
        }
        expired_invitations {
          id
          login
          email
          email_for_org_users
        }
        remaining_invitations
      }
    }
  }
`

const RevokeInvitationToOrganizationMutation = gql`
  mutation RevokeInvitationToOrganizationMutation($userId: String!, $orgId: String!) {
    revokeInvitationToOrganization(input: { user_id: $userId, organization_id: $orgId }) {
      errors {
        attribute
        message
      }
      org {
        users {
          id
          login
          email
          email_for_org_users
        }
        pending_invitations {
          id
          login
          email
          email_for_org_users
        }
        expired_invitations {
          id
          login
          email
          email_for_org_users
        }
        remaining_invitations
      }
    }
  }
`

const ChangeOrganizationOwnerMutation = gql`
  mutation ChangeOrganizationOwnerMutation($userId: String!, $orgId: String!) {
    changeOrganizationOwner(input: { user_id: $userId, organization_id: $orgId }) {
      org_owner {
        id
        login
        email
      }
      errors {
        attribute
        message
      }
    }
  }
`

const UpdateOrganizationDetailsMutation = gql`
  mutation UpdateOrganizationDetailsMutation($orgId: Int!, $name: String) {
    updateOrganizationDetails(input: { id: $orgId, name: $name }) {
      organization {
        name
      }
      errors {
        attribute
        message
      }
    }
  }
`

const RemoveUserFromOrganizationMutation = gql`
  mutation RemoveUserFromOrganizationMutation($userId: String, $orgId: String!) {
    removeUserFromOrganization(input: { user_id: $userId, organization_id: $orgId }) {
      org_users {
        id
        login
        email
        email_for_org_users
      }
      errors {
        attribute
        message
      }
    }
  }
`

const CreateAppletFeedbackMutation = gql`
  mutation CreateAppletFeedbackMutation($appletId: String!, $like: Boolean!, $opinionCode: String, $text: String) {
    createAppletFeedback(input: { applet_id: $appletId, like: $like, opinion_code: $opinionCode, text: $text }) {
      errors {
        attribute
        message
      }
      applet_feedback {
        like
      }
    }
  }
`

const CreatePlatformSuggestionMutation = gql`
  mutation CreatePlatformSuggestionMutation(
    $channelId: Int!
    $suggestionType: String!
    $suggestionName: String
    $text: String
    $contextChannelId: Int
  ) {
    createPlatformSuggestion(
      input: {
        channel_id: $channelId
        suggestion_type: $suggestionType
        suggestion_name: $suggestionName
        text: $text
        context_channel_id: $contextChannelId
      }
    ) {
      errors {
        attribute
        message
      }
      platform_suggestion {
        suggestion_type
      }
    }
  }
`

const addUserToPlatformSubscription = gql`
  mutation AddServiceSubscriptionMutation(
    $channelId: Int!
    $newApplet: Boolean
    $newTrigger: Boolean
    $newAction: Boolean
  ) {
    addServiceSubscription(
      input: { channel_id: $channelId, new_applet: $newApplet, new_trigger: $newTrigger, new_action: $newAction }
    ) {
      errors {
        attribute
        message
      }
      service_update_subscription {
        id
        new_applet
        new_trigger
        new_action
      }
    }
  }
`

const restorePersonalAppletMutation = gql`
  mutation RestorePersonalAppletMutation($appletId: String!) {
    restorePersonalApplet(input: { applet_id: $appletId }) {
      normalized_applet {
        archived
      }
      errors {
        attribute
        message
      }
    }
  }
`

const reportChannelFeedbackAbuseMutation = gql`
  mutation ReportChannelFeedbackAbuseMutation($feedbackType: String!, $id: Int!, $textApproved: Boolean!) {
    reportChannelFeedbackAbuse(input: { feedback_type: $feedbackType, id: $id, text_approved: $textApproved }) {
      errors {
        attribute
        message
      }
    }
  }
`

const ForwardSuggestionQuery = gql`
  query ForwardSuggestionQuery($channel: String!, $type: String!, $cursor: String, $order: String) {
    channel(module_name: $channel) {
      platform_suggestions(suggestion_type: $type, order: $order, first: 10, after: $cursor) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        total_count
        edges {
          cursor
          node {
            suggestion_name
            text
            created_at
            id
            text_approved
            context_channel {
              monochrome_image_url
              brand_color
              name
            }
            context_channel_published
            user {
              login
              profile_image_url
            }
          }
        }
      }
    }
  }
`

const BackwardSuggestionQuery = gql`
  query BackwardSuggestionQuery($channel: String!, $type: String!, $cursor: String, $order: String) {
    channel(module_name: $channel) {
      platform_suggestions(suggestion_type: $type, order: $order, last: 10, before: $cursor) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        total_count
        edges {
          cursor
          node {
            suggestion_name
            text
            created_at
            id
            text_approved
            context_channel {
              monochrome_image_url
              brand_color
              name
            }
            context_channel_published
            user {
              login
              profile_image_url
            }
          }
        }
      }
    }
  }
`

const ForwardAppletQuery = gql`
  query ForwardAppletQuery($appletId: String, $order: String, $cursor: String) {
    normalized_applets(ids: [$appletId]) {
      applet_feedbacks(order: $order, first: 10, after: $cursor) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        total_count
        edges {
          cursor
          node {
            text
            created_at
            id
            like
            opinion_code
            user {
              login
              profile_image_url
            }
          }
        }
      }
    }
  }
`

const BackwardAppletQuery = gql`
  query BackwardAppletQuery($appletId: String!, $order: String, $cursor: String) {
    normalized_applets(ids: [$appletId]) {
      applet_feedbacks(order: $order, first: 10, before: $cursor) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        total_count
        edges {
          cursor
          node {
            text
            created_at
            id
            like
            opinion_code
            user {
              login
              profile_image_url
            }
          }
        }
      }
    }
  }
`

const ConnectionAlreadyExistsOnAnotherIFTTTAccountQuery = gql`
  query ConnectionAlreadyExistsOnAnotherIFTTTAccountQuery($appletId: String!) {
    applet(id: $appletId, in_embed_view: true) {
      connection_already_exists
    }
  }
`

const liveConfigurationUpdateMutation = gql`
  mutation LiveConfigurationUpdateMutation($applet_id: String, $metadata: JSON) {
    liveConfigurationUpdate(input: { applet_id: $applet_id, metadata: $metadata }) {
      normalized_applet_configurations {
        slug
        title
        icon
        description
        required
        live_configurations {
          id
          disabled
        }
      }
      errors {
        attribute
        message
      }
    }
  }
`

const AppletFeedbackByUser = gql`
  query AppletFeedbackByUser($appletId: String!) {
    applet(id: $appletId) {
      normalized_applet {
        applet_feedback_by_user
      }
    }
  }
`

export const myAppletsNormalizedAppletFields = gql`
  id
  friendly_id
  config_type
  name
  description
  archived
  monochrome_icon_url
  status
  service_slug
  brand_color
  published
  installs_count
  author
  author_tier
  created_at
  by_service_owner
  intermediate_pro_features
  pro_features
  background_images {
    sm_background_image_url
    background_image_url_1x
  }
  channels {
    name
    module_name
    monochrome_image_url
    brand_color
  }
`

export function restoreApplet(appletId) {
  return graphQueryBang(restorePersonalAppletMutation, {
    appletId,
  })
}

export function fetchAppletFeedbackByUser(appletId) {
  return graphQueryBang(AppletFeedbackByUser, {
    appletId,
  }).then(data => data.applet.normalized_applet)
}

export function checkConnectionAlreadyExists(appletId) {
  return graphQueryBang(ConnectionAlreadyExistsOnAnotherIFTTTAccountQuery, {
    appletId,
  }).then(data => data.applet)
}

export function inviteUserToOrganization(emailOrLogin, orgId, allowAccountCreation = true) {
  return graphQueryBang(InviteUserToOrganizationMutation, {
    emailOrLogin,
    orgId,
    allowAccountCreation,
  }).then(data => data.inviteUserToOrganization)
}

export function revokeInvitationToOrganization(userId, orgId) {
  return graphQueryBang(RevokeInvitationToOrganizationMutation, {
    userId,
    orgId,
  }).then(data => data.revokeInvitationToOrganization)
}

export function removeUserFromOrganization(userId, orgId) {
  return graphQueryBang(RemoveUserFromOrganizationMutation, {
    userId,
    orgId,
  }).then(data => data.removeUserFromOrganization)
}

export function changeOrganizationOwner(userId, orgId) {
  return graphQueryBang(ChangeOrganizationOwnerMutation, {
    userId,
    orgId,
  }).then(data => data.changeOrganizationOwner.org_owner)
}

export function updateOrganizationDetails(orgId, name) {
  return graphQueryBang(UpdateOrganizationDetailsMutation, {
    orgId,
    name,
  }).then(data => data.updateOrganizationDetails.organization)
}

export function updateEmbeddedRedirectURIs(serviceModuleName, redirects) {
  return graphQueryBang(UpdateEmbeddedRedirectUrisMutation, {
    serviceModuleName,
    redirects,
  }).then(data => data.updateEmbeddedRedirectUris.channel.embedded_redirect_uris)
}

export function sendDownloadLink(phoneNumber, platform, forApplet) {
  return graphQueryBang(SendDownloadLinkMutation, {
    phoneNumber,
    platform,
    forApplet,
  }).then(data => {
    return data.sendDownloadLinkMutation
  })
}

export function addToFavoriteServicesTable(channelNames) {
  return graphQueryBang(AddChannelToFavoriteChannelsMutation, {
    channelNames,
  }).then(data => {
    return data.addChannelToFavoriteChannelsMutation
  })
}

export function sendAppletFeedback(appletId, like, opinionCode, text) {
  return graphQueryBang(CreateAppletFeedbackMutation, {
    appletId,
    like,
    opinionCode,
    text,
  }).then(data => {
    return data.createAppletFeedback
  })
}

export function sendPlatformSuggestion(channelId, suggestionType, suggestionName, text, contextChannelId) {
  return graphQueryBang(CreatePlatformSuggestionMutation, {
    channelId,
    suggestionType,
    suggestionName,
    text,
    contextChannelId,
  }).then(data => {
    return data.createPlatformSuggestion
  })
}

export function platformUpdateSubscription(channelId, subscriptions) {
  let newTrigger = subscriptions["trigger"] || null,
    newAction = subscriptions["action"] || null,
    newApplet = subscriptions["applet"] || null

  return graphQueryBang(addUserToPlatformSubscription, {
    channelId,
    newApplet,
    newTrigger,
    newAction,
  }).then(data => {
    data.addServiceSubscription
  })
}

export function reportChannelFeedbackAbuse(feedbackType, id, textApproved) {
  return graphQueryBang(reportChannelFeedbackAbuseMutation, {
    feedbackType,
    id,
    textApproved,
  }).then(data => data.reportChannelFeedbackAbuse)
}

export function liveConfigurationUpdate(applet_id, metadata) {
  return graphQueryBang(liveConfigurationUpdateMutation, {
    applet_id,
    metadata,
  }).then(data => data.liveConfigurationUpdate)
}

export function getChannelSuggestions(channel, type, order, cursor, isForwardSuggestionQuery = true) {
  if (isForwardSuggestionQuery) {
    return graphQueryBang(ForwardSuggestionQuery, {
      channel,
      type,
      cursor,
      order,
    }).then(data => data.channel.platform_suggestions)
  }
  return graphQueryBang(BackwardSuggestionQuery, {
    channel,
    type,
    cursor,
    order,
  }).then(data => data.channel.platform_suggestions)
}

export function getAppletFeedback(appletId, order, cursor, isForwardSuggestionQuery = true) {
  if (isForwardSuggestionQuery) {
    return graphQueryBang(ForwardAppletQuery, { appletId, order, cursor }).then(
      data => data.normalized_applets[0].applet_feedbacks
    )
  }
  return graphQueryBang(BackwardAppletQuery, { appletId, order, cursor }).then(
    data => data.normalized_applets[0].applet_feedbacks
  )
}

export function getUserLiveApplets(limit, offset, applet_channel) {
  const UserLiveAppletsQuery = gql`
    query UserLiveAppletsQuery($offset: Int, $limit: Int, $applet_channel: String) {
      live_applets(limit: $limit, offset: $offset, order: my_applets, applet_channel: $applet_channel) {
        applet {
          normalized_applet {
            ${myAppletsNormalizedAppletFields}
          }
        }
      }
    }
  `

  return graphQueryBang(UserLiveAppletsQuery, { limit, offset, applet_channel })
}

export function getUserApplets(userLogin, limit, offset) {
  const UserAppletsQuery = gql`
    query UserAppletsQuery($user_login: String, $offset: Int, $limit: Int) {
      applets(user_login: $user_login, include_draft: true, include_archived: false, limit: $limit, offset: $offset) {
        normalized_applet {
          ${myAppletsNormalizedAppletFields}
        }
      }
    }
  `

  return graphQueryBang(UserAppletsQuery, { user_login: userLogin, limit, offset })
}

export function getUserArchivedApplets(userLogin, limit, offset) {
  const UserAppletsQuery = gql`
    query UserAppletsQuery($user_login: String, $offset: Int, $limit: Int) {
      applets(
        user_login: $user_login,
        include_draft: true,
        archived: true,
        limit: $limit,
        offset: $offset,
        order: "created_at_asc"
      ) {
        normalized_applet {
          ${myAppletsNormalizedAppletFields}
          pro_features
        }
      }
    }
  `

  return graphQueryBang(UserAppletsQuery, {
    user_login: userLogin,
    limit,
    offset,
  })
}

export async function getMyAppletsTotal() {
  const UserAppletsTotalQuery = gql`
    query UserAppletsTotalQuery {
      my_applets_total
    }
  `

  const data = await graphQueryBang(UserAppletsTotalQuery)
  return data.my_applets_total
}

export async function graphQuery(query, variables = {}, configs = {}) {
  const isMutation = query.trim().startsWith("mutation")
  if (window.App.jwtExp && !configs["retried"] && new Date().getTime() / 1000 + 60 > window.App.jwtExp) {
    await refreshJWT()
  }
  try {
    const response = await fetch("/api/v3/graph", {
      method: "post",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Token jwt="${window.App.userJWT}"`,
      },
      body: JSON.stringify({ query, variables }),
    })
    if (!response.ok) {
      return Promise.reject(response)
    } else {
      const result = await response.json()
      const error = result.errors?.[0]
      const refreshed = !configs["retried"] && error?.message.includes("Authentication failed") && (await refreshJWT())

      if (refreshed) {
        return graphQuery(query, variables, { retried: true })
      }
      if (!error && isMutation) {
        if (configs["awaitClearCache"]) {
          await clearCache()
        } else {
          clearCache()
        }
      }

      return result
    }
  } catch (err) {
    return Promise.reject({
      response: err.statusText,
      status: err.status,
      error: err,
    })
  }
}

async function refreshJWT() {
  return fetch("/jwt/refresh", {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then(response => response.json())
    .then(data => {
      if (data.jwt) {
        window.App.userJWT = data.jwt
        window.App.jwtExp = data.exp
        return true
      }
      return false
    })
}

export async function clearCache() {
  fetch("/session/clear_cache", {
    method: "post",
    headers: {
      "X-CSRF-Token": await fetchAuthenticityToken(),
    },
  }).catch(e => window.App.allowDebugErrorMessages && console.warn(e))
}

export function graphQueryBang(query, variables = {}, configs = {}) {
  return graphQuery(query, variables, configs)
    .then(response => {
      if (response.data) {
        return response.data
      } else {
        const err = new Error("Failed GraphQL call")
        err.details = response.errors
        throw err
      }
    })
    .catch(e => {
      if (configs.shouldRetryOnFailure) {
        configs.shouldRetryOnFailure = false
        return graphQueryBang(query, variables, configs)
      } else {
        throw e
      }
    })
}

// We have a specific format to handle with error on StoredField (app/components/stored_field.js).
// If we use GQL via controllers, we already have the structure being created on Web::Graph::Error::UnprocessableEntity.
// Copying the same logic to JS layer.
export function parseGraphValidationErrors(errors) {
  let outputError = {}
  let result, root, node, attr

  errors.forEach(error => {
    if (error.attribute) {
      result = error.attribute.split("/")
      root = result[1]
      node = result[2].replace(/~1/g, "/")
      attr = result[3]

      if (result[4]) {
        let multi_same_action_index = result[3]
        node = `${node}/${multi_same_action_index}`
        attr = result[4]
      }

      outputError[root] = outputError[root] || {}
      if (attr && node) {
        outputError[root][node] = outputError[root][node] || {}
        outputError[root][node][attr] = outputError[root][node][attr] || []
        outputError[root][node][attr].push(error.message)
      } else if (node) {
        outputError[root][node] = outputError[root][node] || []
        outputError[root][node].push(error.message)
      }
    }
  })

  return {
    original: errors.map(error => ({
      data: error,
    })),
    validation_errors: outputError,
  }
}
