import { useState, useRef, useEffect } from "react"
import classnames from "classnames"
import sharingIcon from "images/web/sharing/sharing_icon.svg"
import sharingIconDark from "images/web/sharing/sharing_icon_dark.svg"
import parse from "html-react-parser"

import DesktopShareMenu from "./desktop_share_menu"
import MobileShareMenu from "./mobile_share_menu"
import Modal from "shared/components/modal"

import "./share_style.scss"

interface Props {
  id: string
  location: string
  shareUrl: string
  title: string
  onLightBackground?: boolean
}

const ShareButton = ({ shareUrl, title, id, location, onLightBackground }: Props) => {
  const [showModal, setShowModal] = useState(false)
  const shareURLInput = useRef(null)
  const [isDesktop, setIsDesktop] = useState(true)

  const ResponsiveShareMenu = isDesktop ? DesktopShareMenu : MobileShareMenu

  useEffect(() => {
    setIsDesktop(window.matchMedia?.(`(min-width: 724px)`)?.matches)
  }, [])

  const onInputClick = e => {
    e.target.select()
  }

  const onCopy = e => {
    shareURLInput.current.select?.()
    navigator.clipboard.writeText(shareUrl)

    const copyButton = e.target
    copyButton.textContent = "Copied"
    copyButton.setAttribute("disabled", true)
    setTimeout(() => {
      copyButton.removeAttribute("disabled")
      copyButton.textContent = "Copy"
    }, 1000)
  }

  const onFacebookClick = e => {
    e.preventDefault()
    window.FB.ui(
      {
        method: "share",
        href: shareUrl,
      },
      () => {}
    )
  }

  const onTwitterClick = e => {
    e.preventDefault()
    window.open(
      `https://twitter.com/intent/tweet?text=${title}&url=${shareUrl}`,
      "Join IFTTT",
      "scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=550,height=420"
    )
  }

  return (
    <div styleName={classnames("share-wrapper", { mobile: !isDesktop })}>
      <Modal show={showModal} closeListener={() => setShowModal(false)}>
        <ResponsiveShareMenu
          shareUrl={shareUrl}
          shareURLInput={shareURLInput}
          onInputClick={onInputClick}
          onTwitterClick={onTwitterClick}
          onFacebookClick={onFacebookClick}
          onCopy={onCopy}
          title={title}
        />
      </Modal>
      <button
        aria-label="share"
        styleName={`share-button ${onLightBackground ? "light-background" : ""}`}
        className="button-tertiary button-outlined button-with-icon only-icon-on-mobile share-button-fixed"
        onClick={() => {
          window.App.Utils?.logCustomDatadogAction?.("share_button", { id, location })
          setShowModal(true)
        }}
      >
        <span>{parse(onLightBackground ? sharingIconDark : sharingIcon)}</span>
        <span className="hide-on-xs">Share</span>
      </button>
    </div>
  )
}

export default ShareButton
