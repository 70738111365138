import Qs from "qs"

export default function appletOrConnectionPath(resource, extraParams) {
  const namespace = { static: "/applets", dynamic: "/connections" }
  const path = namespace[resource.config_type] || "/applets"
  const params = extraParams ? `?${Qs.stringify(extraParams)}` : ""
  let returnPath = `${path}/${resource.id}${params}`

  if (resource.friendly_id) {
    returnPath = `${path}/${resource.friendly_id}${params}`
  } else if (resource.name) {
    const nameParameterized = resource.name.toLowerCase().replace(/[^a-z0-9]+/g,'-').replace(/(^-|-$)/g,'')
    returnPath = `${path}/${resource.id}-${nameParameterized}${params}`
  }

  return returnPath
}
