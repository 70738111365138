import { Component } from "react"
import PropTypes from "prop-types"
import { Spinner } from "spin.umd.js"

// Forked from https://github.com/thomasboyt/react-spin
export default class ReactSpinner extends Component {
  componentDidMount() {
    this.spinner = new Spinner(this.props.config)
    if (!this.props.stopped) {
      this.spinner.spin(this.containerRef)
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.stopped === true && !this.props.stopped) {
      this.spinner.stop()
    } else if (!newProps.stopped && this.props.stopped === true) {
      this.spinner.spin(this.containerRef)
    }
  }

  componentWillUnmount() {
    this.spinner.stop()
  }

  render() {
    return <span ref={r => (this.containerRef = r)} />
  }
}

ReactSpinner.propTypes = {
  config: PropTypes.object,
  stopped: PropTypes.bool,
}

ReactSpinner.defaultProps = {
  config: { length: 6, width: 4, radius: 20, corners: 0 },
}
