import { xhrPromise, rejectedXHRPromiseLogger } from "shared/lib/xhr_promise"

export function put(url, body, headers = {}) {
  return xhrPromise(url, { method: "put", body, headers })
}

export function post(url, body, headers = {}) {
  return xhrPromise(url, { method: "post", body, headers })
}

export function patch(url, body, headers = {}) {
  return xhrPromise(url, { method: "PATCH", body, headers })
}

export function get(url, params = {}, headers = {}) {
  return xhrPromise(url, { method: "get", cache: "no-cache", data: params, headers })
}

export function del(url, body, headers = {}) {
  return xhrPromise(url, { method: "delete", body, headers })
}

export function logError(callback) {
  return rejectedXHRPromiseLogger(callback)
}

export function putFile(url, file) {
  return xhrPromise(url, {
    method: "put",
    data: file,
    headers: {
      "Content-Type": file.type,
    },
    processData: false,
  })
}
