import PropTypes from "prop-types"
import { serviceShape } from "app/lib/shared_prop_types"
import "./service_card.scss"

export default function ServiceCard(props) {
  const backgroundColor = props.service.brand_color.startsWith("#")
    ? props.service.brand_color
    : `#${props.service.brand_color}`

  return (
    <a
      styleName="service-card"
      className={props.extraClass}
      style={{ backgroundColor }}
      onClick={props.onClick}
      href={props.path}
    >
      <img
        src={props.service.icon_bw || props.service.lrg_monochrome_image_url || props.service.lrg_variant_image_url}
        title={props.service.name}
        alt={props.service.name}
        loading={props.lazyLoad ? "lazy" : "eager"}
        width="54"
        height="54"
        styleName="service-card-image"
      />
      <span styleName="service-card-title">{props.service.name}</span>
    </a>
  )
}

ServiceCard.propTypes = {
  service: serviceShape.isRequired,
  onClick: PropTypes.func,
  path: PropTypes.string,
  extraClass: PropTypes.string,
  lazyLoad: PropTypes.bool,
}

ServiceCard.defaultProps = {
  service: {},
  extraClass: "",
  lazyLoad: true,
}
