// Go back to the site for a refreshed authenticity_token if serving a cached page
// with forms

import { get } from "../../shared/lib/api"

let authenticityTokenPromise

export default function RefreshFormAuthenticityToken() {
  const dateHash = new Date().getTime()
  return (authenticityTokenPromise = get(`/csrf_token.json?gf=${dateHash}`)
    .then(data => {
      let returnToken = (window.App.authenticityToken = data.token)
      document.querySelector("meta[name=csrf-param]").content = data.param
      document.querySelector("meta[name=csrf-token]").content = data.token

      document.querySelectorAll(`input[name=${data.param}]`).forEach(input => {
        input.value = data.token
      })

      return returnToken
    })
    .catch(() => {
      if (window.App.allowDebugErrorMessages) {
        console.log("Failed to refresh authenticity_token")
      }
    }))
}

export async function fetchAuthenticityToken(mustBeFresh = false) {
  const shouldBeRefreshed =
    window.App.authenticityToken !== document.querySelector("meta[name=csrf-token]")?.content ||
    window.performance.now() / 1000 > 600 ||
    (mustBeFresh && !authenticityTokenPromise)
  if (shouldBeRefreshed) {
    return await RefreshFormAuthenticityToken()
  } else if (authenticityTokenPromise) {
    return await authenticityTokenPromise
  } else {
    return window.App.authenticityToken
  }
}
