import uniq from "lodash/uniq"

export const initialState = {
  allServices: [],
  allServicesCategories: ["All services"],
  loadAllServicesPath: "",
  loading: false,
  serviceCategoryFilter: "All services",
}

const setAllServices = services => {
  return {
    type: "SET_ALL_SERVICES",
    services,
  }
}

export const setCategoryFilter = category => {
  return {
    type: "SET_CATEGORY_FILTER",
    category,
  }
}

const setIsLoading = isLoading => {
  return {
    type: "TOGGLE_IS_LOADING",
    isLoading,
  }
}

export const fetchAllServices = () => (dispatch, getState) => {
  dispatch(setIsLoading(true))
  const { loadAllServicesPath } = getState().serviceResults

  fetch(loadAllServicesPath)
    .then(res => res.json())
    .then(services => dispatch(setAllServices(services)))
    .finally(() => dispatch(setIsLoading(false)))
}

export default function getMoreResults(state = initialState, action) {
  switch (action.type) {
    case "SET_ALL_SERVICES":
      return {
        ...state,
        allServices: action.services,
        allServicesCategories: uniq(action.services.map(service => service.category?.name).filter(Boolean)).sort(),
      }

    case "SET_CATEGORY_FILTER":
      return {
        ...state,
        serviceCategoryFilter: action.category,
      }

    case "TOGGLE_IS_LOADING":
      return {
        ...state,
        loading: action.isLoading,
      }

    default: {
      return state
    }
  }
}
