import { captureMessage, captureException } from "@sentry/react"
import { fetchAuthenticityToken } from "app/scripts/refresh_form_authenticity_token"

export async function xhrPromise(providedUrl, options) {
  let url = providedUrl

  options.headers = Object.assign(
    {
      "x-requested-with": "XMLHttpRequest",
    },
    options.headers
  )

  if (!options.method || options.method.toLowerCase() === "get") {
    let queryString = new URLSearchParams(options.data).toString()
    if (queryString) url = `${url}?${queryString}`
  } else {
    await updateOptions(options)
  }

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(response => {
        if (!response.ok) {
          reject({
            response: response.json() || response.text(),
            status: response.status,
            error: response.statusText,
          })
        }

        return response
      })
      .then(async response => {
        const contentType = response.headers.get("Content-Type")
        const text = await response.text()

        if (/text\/javascript/.test(contentType)) {
          const validHost = ["ifttt.com", "web-rehearsal.ifttt.com", "ifttt.me"].includes(
            new URL(response.url).hostname
          )
          if (["post", "put", "patch"].includes(options.method.toLowerCase()) && validHost) {
            eval(text)
          }
          return text
        } else if (/application\/json/.test(contentType)) {
          return text ? JSON.parse(text) : {}
        } else if (/text\/html/i.test(contentType)) {
          return text
        }
      })
      .then(resolve)
      .catch(error => {
        reject({
          response: "",
          status: "",
          error,
        })
      })
  })
}

export function logRejectedXHRPromise(response, status, error) {
  const context = { tags: { status }, extra: { response } }
  if (error instanceof Error) {
    captureException(error, context)
  } else if (typeof error === "string" && error.length > 0) {
    captureMessage(`XHR Promise failed with error: ${error}`, context)
  } else {
    captureMessage(`XHR Promise failed with status: ${status}`, context)
  }

  if (window.App.allowDebugErrorMessages) {
    console.warn("catch", response, status, error)
  }
}

export function rejectedXHRPromiseLogger(callback = () => true) {
  return function ({ response, status, error }) {
    logRejectedXHRPromise(response, status, error)
    return callback({ response, status, error })
  }
}

async function updateOptions(options) {
  options.redirect = "error"

  options.headers = Object.assign(
    {
      Accept: options.headers.Accept || "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": await fetchAuthenticityToken(),
    },
    options.headers
  )

  if (options.data) {
    options.body = options.data
  } else if (typeof options.body !== "string") {
    options.body = JSON.stringify(options.body)
  }
}
