import linkedinIcon from "images/web/sharing/linkedin_icon.svg"
import redditIcon from "images/web/sharing/reddit_icon.svg"
import facebookIcon from "images/web/sharing/facebook_icon.svg"
import twitterIcon from "images/web/sharing/twitter_x_icon.svg"
import { ShareMenuProps } from "./share_menu_types"
import parse from "html-react-parser"

import "./share_style.scss"

const MobileShareMenu = ({
  title,
  shareUrl,
  shareURLInput,
  onInputClick,
  onTwitterClick,
  onFacebookClick,
  onCopy,
}: ShareMenuProps) => {
  return (
    <div styleName="share-modal mobile">
      <h1 styleName="mobile-share-title">{title}</h1>
      <p className="txt-body-3" styleName="cta">
        Send this automation to anyone using the options below.
      </p>
      <div styleName="share-icons">
        <a
          onClick={onTwitterClick}
          href={`https://twitter.com/intent/tweet?url=${shareUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>{parse(twitterIcon)}</span>
        </a>
        <a
          onClick={onFacebookClick}
          href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>{parse(facebookIcon)}</span>
        </a>
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>{parse(linkedinIcon)}</span>
        </a>
        <a href={`https://reddit.com/submit?url=${shareUrl}`} target="_blank" rel="noopener noreferrer">
          <span>{parse(redditIcon)}</span>
        </a>
      </div>
      <p className="txt-body-3">Or copy link</p>
      <input type="text" value={shareUrl} readOnly ref={shareURLInput} onClick={onInputClick} />
      <button styleName="copy" className="button button-primary" onClick={onCopy}>
        Copy
      </button>
    </div>
  )
}

export default MobileShareMenu
