import linkedinIcon from "images/web/sharing/linkedin_icon.svg"
import redditIcon from "images/web/sharing/reddit_icon.svg"
import facebookIcon from "images/web/sharing/facebook_icon.svg"
import twitterIcon from "images/web/sharing/twitter_x_icon.svg"
import { ShareMenuProps } from "./share_menu_types"
import parse from "html-react-parser"

import "./share_style.scss"

const DesktopShareMenu = ({
  shareUrl,
  shareURLInput,
  onInputClick,
  onTwitterClick,
  onFacebookClick,
  onCopy,
}: ShareMenuProps) => {
  return (
    <div styleName="share-modal">
      <h4>Share this using the options below:</h4>
      <input type="text" value={shareUrl} readOnly ref={shareURLInput} onClick={onInputClick} />
      <div styleName="share-icons">
        <a
          onClick={onTwitterClick}
          href={`https://twitter.com/intent/tweet?url=${shareUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>{parse(twitterIcon)}</span>
        </a>
        <a
          onClick={onFacebookClick}
          href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>{parse(facebookIcon)}</span>
        </a>
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>{parse(linkedinIcon)}</span>
        </a>
        <a href={`https://reddit.com/submit?url=${shareUrl}`} target="_blank" rel="noopener noreferrer">
          <span>{parse(redditIcon)}</span>
        </a>
        <button styleName="copy" className="button button-tertiary" onClick={onCopy}>
          Copy
        </button>
      </div>
    </div>
  )
}

export default DesktopShareMenu
